import React, { useMemo } from "react";
import {
    Box,
    H4,
    Grid,
    P,
} from '@saladbob/sassafras'
import Link from "next/link";

import { Hof } from "../../types";
import Image from "../media/Image";

type Props = {
    hof: Hof,
    depth?: string,
    bgColor?: string,
    txtColor?: string,
    year?: boolean,
}


const HofCard: React.FC<Props> = ({ hof, bgColor, txtColor, year, depth }) => {
    const showCaption = useMemo(() => {
        const regex = /[\s'"!#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~]/g;
        const fullName = hof.fullName?.toLowerCase().replace(regex, '');
        const caption = hof.caption?.toLowerCase().replace(regex, '');
        return hof.fullName != undefined && fullName != caption;
    }, [hof.fullName, hof.caption]);
    return (
        <Link href={`/hall-of-fame/${hof.id}`}>
            <Grid 
                columns="1fr"
                rows="min-content 1fr"
                minHeight="auto"
                height="100%"
                depth={depth}
            >
                <Image
                    image={hof.image}
                    size="card"
                    alt={hof.caption}
                    loading="lazy"
                    aspectRatio="1/1"
                />
                <Box padding="md" verticalAlign="center" align="center" bgColor={bgColor || 'tertiary'}>
                    {showCaption && (
                        <P font="leader" align="center" color={txtColor || 'black'} mb="0">{hof.fullName}</P>
                    )}
                    <H4 align="center" color={txtColor || 'black'}>
                        {hof.caption}
                    </H4>
                    {year && (<H4 font="p" mb="0" mt="auto" ms="auto" color={txtColor || 'black'} style={{ opacity: 0.5 }}>{hof.year}</H4>)}
                </Box>
            </Grid>
        </Link>
    );
}

export default HofCard;