import React from 'react';
import {
    Container, Btn, Box, H3, P,
} from '@saladbob/sassafras'

import Link from 'next/link';

import HofCard from '../../hof/HofCard';
import Carousel from '../../media/Carousel';
import { Block } from '../../../types';

type Props = Block & {
    HofTitleFont?: string,
    HofSubtitleFont?: string,
};

const HofSlider: React.FC<Props> = ({
    hofs,
    title,
    subtitle,
    columns,
    buttons,
    dots,
    contain,
    verticalPadding: vp,
    horizontalPadding: hp,
    gap,
    mb,
    progress,
    bgColor,
    dotsColor,
    buttonsColor,
    titleColor,
    subtitleColor,
    autoplay,
    duration,
    HofTitleFont: titleFont,
    HofSubtitleFont: subtitleFont,
    minHeight,
}) => {
    return (
        <Box
            minHeight={minHeight}
            mb={mb}
            padding={{ phone: [vp, hp ? 'md' : 0], laptop: [vp, hp] }}
            bgColor={bgColor}
            justify="center"
            align="center"
            style={{
                backgroundAttachment: 'fixed',
            }}
        >
            <Container size={contain} height="min-content">
                {title != undefined && subtitle != undefined && (
                    <Box mb={{ phone: 'md', laptop: 'xl' }}>
                        {title && (<H3 color={titleColor} font={titleFont ? { phone: 'h1', laptop: titleFont } : 'h1'} mb="sm" uppercase align="center">{title}</H3>)}
                        {subtitle && (<P color={subtitleColor} font={subtitleFont ? { phone: 'leader', laptop: subtitleFont } : 'leader' } align="center">{subtitle}</P>)}
                    </Box>
                )}
                <Carousel
                    columns={columns}
                    progress={progress}
                    buttons={buttons}
                    dots={dots}
                    gap={gap}
                    autoplay={autoplay}
                    duration={duration}
                    buttonsColor={buttonsColor}
                    dotsColor={dotsColor}
                >
                    {hofs.map((hof) => (<HofCard key={hof.id} year hof={hof} /> ))}
                </Carousel>
                <Box justify="center" pt="xl">
                    <Link href="/hall-of-fame">
                        <Btn color={titleColor} txtColor={titleColor} size="lg" outline>
                            View All
                        </Btn>
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}

export default HofSlider;
